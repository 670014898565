import React from "react";
import {withTranslation} from "react-i18next";
import AboutPage from "../../userContent/userPages/AboutPage/AboutPage";

const About = () => {
    return (
        <AboutPage/>
    )
}
/*
About.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'About').then((text) => {
        return text;
    })
}
*/
export default withTranslation()(About)