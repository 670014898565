import React, {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import ImageCarouselComponent from "../../components/editImage/ImageCarouselComponent";
import sharedConfig from "../../../shared/sharedConfig"
import {useLocation} from "react-router-dom";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";

const namespace = 'apartments'

const showApartment = (i18n) => {

    return sharedConfig.rooms.map(room => (
        <>
            <div id={room.replace(/\s/g, '')} key={room} className={'row centered'}
                 style={{backgroundColor: 'rgb(255, 252, 246)'}}>
                <div className={'ten wide computer sixteen wide mobile column padding-zero'}>
                    <ImageCarouselComponent id={'carousel-' + room}
                                            showButtons={true}
                                            infinite={true}
                                            isPlaying={false}
                                            interval={5000}
                                            naturalSlideWidth={1}
                                            naturalSlideHeight={1}/>
                </div>
                <div className={'six wide computer sixteen wide mobile column  room-container-padding'}>
                    <section>
                        <h1>{room}</h1>
                        <p><b> <EditHTMLComponent id={'overview-' + room} namespace={namespace}/></b></p>
                        <div>
                            <EditHTMLComponent id={'roomProperties-' + room} namespace={namespace}
                                               className={'room-properties'}/>
                        </div>
                    </section>
                </div>
            </div>
            <div className={'row centered'} style={{backgroundColor: 'rgb(255, 252, 246)'}}>
                <div className={'ten wide computer sixteen wide mobile column'}
                     style={{padding: 0}}>
                    <section className={'room-grid-padding'}
                             style={{background: '#9fc0581A', height: "100%"}}>
                        <EditHTMLComponent id={'description-' + room} namespace={namespace}/>
                    </section>
                </div>
                <div className={'six wide computer sixteen wide mobile column room-container-padding'}>
                    <section className={"room-overview-info"}>

                        <EditHTMLComponent id={'disclaimer-' + room} namespace={namespace}/>
                        <EditHTMLComponent className={'room-price'} id={'price-' + room} namespace={namespace}/>
                        <br/>
                        <a href={`mailto:info@chateauleval.fr?subject=${i18n.t("component:descriptions.check_available")} ${room}&body=${i18n.t("component:descriptions.mail", {room: room})}`}>
                            <button
                                className={'button ui right floated'}>{i18n.t("component:descriptions.check_available")}</button>
                        </a>
                    </section>
                </div>

            </div>
            <Divider style={{borderTop: 'none', borderBottom: '3px solid lightgrey'}}/>
        </>
    ))
}

const RoomOverView = (props) => {
    const {i18n} = props;
    const location = useLocation();

    useEffect(_ => {
        setTimeout(_ => {
            if (location.hash && typeof window !== "undefined" && typeof document !== "undefined") {
                let element = document.getElementById(decodeURI(location.hash.substring(1, location.hash.length)));
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }

            }
        }, 500)
    }, [location.hash])

    return (
        <div className={'grid ui room-grid-padding max-width'}>
            <div className={'row centered'}>
                <div className={'sixteen wide computer sixteen wide mobile column row-padding-top'}>
                    <EditHTMLComponent id={'overview-title-' + namespace} namespace={namespace}/>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'eight wide computer sixteen wide mobile column'}>
                    <section className={'room-overview-subtext'}>
                        <EditHTMLComponent id={'overview-subtext-1'} namespace={namespace}/>
                    </section>
                </div>
                <div className={'eight wide computer sixteen wide mobile column'}>
                    <section className={'room-overview-subtext'}>
                        <EditHTMLComponent id={'overview-subtext-2'} namespace={namespace}/>
                    </section>
                </div>
            </div>
            <Divider style={{borderTop: 'none', borderBottom: '3px solid lightgrey', margin: "auto 0"}}/>
            {showApartment(i18n)}
            <div className={'row centered'}>
                <div className={'sixteen wide column'}>
                    <section style={{textAlign:"right"}}>
                        <EditHTMLComponent id={'overview-subtext-3'} namespace={namespace}/>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(['component'])(RoomOverView)