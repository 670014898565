import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import userStore from "../adminComponents/userManagement/userStore";
import config from "../../config/main.config";
import {fetchAllImages, getFromLocalStorage} from "../../helper/util";

if (typeof document !== "undefined") {
    require("pure-react-carousel/dist/react-carousel.es.css");
}

function addSelectedImages(images, setIsSelectModalOpen, frontendId, setImages) {

    let linksToPut = images;
    fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(allImages => {
                //  ＼（〇_ｏ）／
                if (allImages.length > 0) {
                    for (let i = 0; i < allImages.length; i++) {
                        for (let j = 0; j < linksToPut.length; j++) {
                            if (linksToPut[j].link === allImages[i].link) {
                                linksToPut.splice(j, 1);
                            }
                        }
                    }
                }
                setImages(allImages.concat(linksToPut))
                if (linksToPut.length > 0) {
                    //put links to component
                    fetch(config.BASE_URL + 'admin/images/selection/', {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + getFromLocalStorage("token")
                        },
                        body: JSON.stringify({frontendId: frontendId, links: linksToPut})
                    }).then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                            setIsSelectModalOpen(false);
                        }
                    }).catch(() => {
                    });
                } else {
                    setIsSelectModalOpen(false);
                }
            })
        }
    }).catch(e => {
        console.error("ERROR: ", e);
    })
}

function fetchImagesFrontendId(setImages, frontendId, setSelectImage, selectImage) {
    fetch(config.BASE_URL + "images/frontendId/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                for (let i = 0; i < json.length; i++) {
                    json[i].selected = json[i].frontendId === frontendId;
                    for (let j = 0; j < selectImage.length; j++) {
                        if (json[i].link === selectImage[j].link) {
                            let newArray = selectImage;
                            newArray[j].selected = true;
                            setSelectImage(newArray);
                        }
                    }
                }
                setImages(json);
            });

        } else {
            console.error(response.status)
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
            }
        }
    );
}

function deleteImage(link, id, setImage, setSelectImage, selectImage) {
    fetch(config.BASE_URL + "images/frontendId", {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({link: link, id: id})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            fetchImagesFrontendId(setImage, id, setSelectImage, selectImage);
        } else {
            console.error(response.status)
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
            }
        }
    );
}

function getGalleryItems(images) {
    let items = [];
    for (let i = 0; i < images.length; i++) {
        items.push(
            <Slide index={i} key={i}>
                <img
                    alt={images[i].alt ? images[i].alt : "Carousel Item " + i}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%'
                    }}
                    src={config.BASE_URL_IMAGES + 'all-images/' + images[i].link}/>
            </Slide>
        )
    }
    return items;
}

function uploadThumbnailImage(event, frontendId, images, setSelectModalOpen, setImages, setSelectImage) {
    const data = new FormData();

    let files = Array.from(event.target.files);

    //Send files
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        data.append("images", file);
    }
    fetch(config.BASE_URL + 'admin/images/upload-images/' + frontendId, {
        method: "POST",
        body: data,
        headers: {
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            addSelectedImages(images, setSelectModalOpen, frontendId, setImages);
            fetchImagesFrontendId(setImages, frontendId, setSelectImage, images);
        }
    }).catch(() => {

    });

}

const ImageCarouselComponent = (props) => {
    const {
        id,
        t,
        naturalSlideWidth,
        naturalSlideHeight,
        interval,
        infinite,
        isPlaying,
        showButtons,
        showDots,
        style
    } = props;
    const [images, setImages] = useState([]);
    const [selectImage, setSelectImage] = useState([]);
    const [prevSelectImage, setPrevSelectImage] = useState([]);
    const [isSelectImageModalOpen, setIsSelectImageModalOpen] = useState(false);

    useEffect(_ => {
        fetchAllImages().then(images => {
            setSelectImage(images);
            setPrevSelectImage(images);
            fetchImagesFrontendId(setImages, id, setSelectImage, images);
        });

    }, []);

    const showImageSlides = getGalleryItems(images);
    let showImages = images.map((image, index) =>
        <Grid.Column key={index} width={5}>
            <Button
                negative
                onClick={() => {
                    if (image.hasOwnProperty("selected")) {
                        image.selected = false;
                    }
                    deleteImage(image.link, id, setImages, setSelectImage, selectImage);
                }}
                attached={"top"}
                className={"gallery-delete-button"}>
                {t("common:common.delete")}
            </Button>
            <img
                src={config.BASE_URL_IMAGES + 'all-images/' + image.link} alt={'image-' + index}
            />
        </Grid.Column>
    );

    let showSelectImages = selectImage.map((item, index) =>
        <Grid.Column key={index} width={5}>
            <img src={config.BASE_URL_IMAGES + 'all-images/' + item.link} alt={'image-' + index}
                 className={selectImage[index].selected ? 'selectedImage' : 'unselectedImage'}
                 loading={"lazy"}
                 style={item.link.includes(".webp") ? {
                     border: 'blueviolet',
                     borderStyle: 'solid',
                     borderWidth: '5px'
                 } : {}}
                 onClick={() => {
                     const newArray = [...selectImage];
                     newArray[index].selected = !selectImage[index].selected;
                     setSelectImage(newArray);
                     if (newArray[index].selected) {
                         setImages([...images, newArray[index]]);

                     } else {
                         deleteImage(newArray[index].link, id, setImages, setSelectImage, selectImage)
                     }
                 }}
            />
            <Icon
                className={selectImage[index].selected ? 'selectedLabel' : 'unselectedLabel'}
                name={selectImage[index].selected ? "check circle outline" : "circle outline"}
            />
        </Grid.Column>
    );

    const displayType = userStore.isAdmin ? "admin" : "user";

    return (
        displayType === "admin" ?
            <Grid>
                <Grid.Row>
                    {showImages}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <input type="file" id="file"
                               onChange={e => uploadThumbnailImage(e, id, images, setIsSelectImageModalOpen, setImages, setSelectImage)}
                               accept="image/*"
                               multiple/>
                        <Button.Group>
                            <Button
                                color={"orange"}
                                onClick={() => setIsSelectImageModalOpen(true)}>
                                {t("common:common.select")}
                            </Button>
                            <Button color={"teal"}>
                                <label htmlFor="file">
                                    {t("common:common.upload")}
                                </label>
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Modal open={isSelectImageModalOpen}
                       className={'margin-auto'}
                       centered={true}>
                    <Modal.Header>{t("common:common.select")}</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid>
                            <Grid.Row>
                                <div style={{display: 'flex', marginBottom: '20px'}}>
                                    <div style={{
                                        background: 'blueviolet',
                                        height: '10px',
                                        width: '10px',
                                        margin: 'auto 10px',
                                    }}/>
                                    <p>.webp</p>
                                </div>
                            </Grid.Row>
                            <Grid.Row>
                                {showSelectImages}
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative icon='delete' onClick={() => {
                            setSelectImage(prevSelectImage);
                            setIsSelectImageModalOpen(false)
                        }}/>
                        <Button
                            positive
                            icon='checkmark'
                            onClick={() => {
                                addSelectedImages(images, setIsSelectImageModalOpen, id, setImages);
                            }}
                        />
                    </Modal.Actions>
                </Modal>
            </Grid>
            : <CarouselProvider
                naturalSlideWidth={naturalSlideWidth}
                naturalSlideHeight={naturalSlideHeight}
                interval={interval}
                totalSlides={images.length}
                infinite={infinite}
                isPlaying={isPlaying}
                style={style ? style : {}}>
                <Slider>
                    {showImageSlides}
                </Slider>
                {
                    images.length > 1 && displayType !== "admin" && showButtons ?
                        <ButtonBack className={'padding-zero'} style={{
                            borderStyle: 'none', background: 'transparent', position: 'absolute',
                            left: '2%', top:'50%'
                        }}>
                            <Icon name={'angle left'} style={{margin:'auto'}} inverted size={"small"} circular/>
                        </ButtonBack>
                        : null
                }
                {
                    images.length > 1 && displayType !== "admin" && showButtons ?
                        <ButtonNext className={'padding-zero'} style={{
                            borderStyle: 'none', background: 'transparent', position: 'absolute',
                            right: '2%',top:'50%'
                        }}>
                            <Icon name={'angle right'} style={{margin:'auto'}} inverted size={"small"} circular/>
                        </ButtonNext>
                        : null
                }
                {
                    images.length > 1 && showDots ?
                        <DotGroup className={'slider-dots'} style={{position:'absolute', bottom:0,width:'100%', textAlign:'center'}}/>
                        : null
                }
            </CarouselProvider>
    )
};

export default withTranslation(['common'])(ImageCarouselComponent)