import React from 'react';
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";
import RoomOverView from "../../ChateauRoomOverview/RoomOverView";

const AboutPage = () => {
    return (
        <div className={'grid ui'} style={{padding: '3rem'}}>
            <div className={'row centered'}>
                <div className={'fourteen wide column'}>
                    <RoomOverView/>
                </div>

            </div>
        </div>


    )
}
AboutPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'About').then((text) => {
        return text;
    })
}
export default withTranslation(['common'])(AboutPage);
